import { __assign, __read, __spreadArray, __values } from "tslib";
import { createObjectTransformers } from './transformers';
import { isPlainObject } from './util';
export var createSnakeParamsInterceptor = function (options) {
    var snake = createObjectTransformers(options === null || options === void 0 ? void 0 : options.caseFunctions).snake;
    return function (config) {
        if (config.params) {
            config.params = snake(config.params, options);
        }
        return config;
    };
};
export var createSnakeRequestTransformer = function (options) {
    var _a = createObjectTransformers(options === null || options === void 0 ? void 0 : options.caseFunctions), snake = _a.snake, header = _a.header;
    return function (data, headers) {
        var e_1, _a, _b;
        if (!(options === null || options === void 0 ? void 0 : options.ignoreHeaders) && isPlainObject(headers)) {
            try {
                for (var _c = __values(Object.entries(headers)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
                    header(value, __assign({ overwrite: true }, options));
                    if (!['common', 'delete', 'get', 'head', 'post', 'put', 'patch'].includes(key)) {
                        delete headers[key];
                        headers[Object.keys(header((_b = {}, _b[key] = null, _b), options))[0]] = value;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return snake(data, options);
    };
};
export var createCamelResponseTransformer = function (options) {
    var camel = createObjectTransformers(options === null || options === void 0 ? void 0 : options.caseFunctions).camel;
    return function (data, headers) {
        if (!(options === null || options === void 0 ? void 0 : options.ignoreHeaders)) {
            camel(headers, __assign({ overwrite: true }, options));
        }
        return camel(data, options);
    };
};
export var applyCaseMiddleware = function (axios, options) {
    var _a, _b, _c;
    axios.defaults.transformRequest = __spreadArray([
        ((_a = options === null || options === void 0 ? void 0 : options.caseMiddleware) === null || _a === void 0 ? void 0 : _a.requestTransformer) ||
            createSnakeRequestTransformer(options)
    ], __read((Array.isArray(axios.defaults.transformRequest)
        ? axios.defaults.transformRequest
        : axios.defaults.transformRequest !== undefined
            ? [axios.defaults.transformRequest]
            : [])), false);
    axios.defaults.transformResponse = __spreadArray(__spreadArray([], __read((Array.isArray(axios.defaults.transformResponse)
        ? axios.defaults.transformResponse
        : axios.defaults.transformResponse !== undefined
            ? [axios.defaults.transformResponse]
            : [])), false), [
        ((_b = options === null || options === void 0 ? void 0 : options.caseMiddleware) === null || _b === void 0 ? void 0 : _b.responseTransformer) ||
            createCamelResponseTransformer(options),
    ], false);
    axios.interceptors.request.use(((_c = options === null || options === void 0 ? void 0 : options.caseMiddleware) === null || _c === void 0 ? void 0 : _c.requestInterceptor) ||
        createSnakeParamsInterceptor(options));
    return axios;
};
