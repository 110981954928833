export var isURLSearchParams = function (value) {
    return (typeof URLSearchParams !== 'undefined' && value instanceof URLSearchParams);
};
export var isFormData = function (value) {
    return typeof FormData !== 'undefined' && value instanceof FormData;
};
export var isPlainObject = function (value) {
    return (typeof value === 'object' &&
        value !== null &&
        Object.prototype.toString.call(value) === '[object Object]');
};
export var isTransformable = function (value) {
    return (Array.isArray(value) ||
        isPlainObject(value) ||
        isFormData(value) ||
        isURLSearchParams(value));
};
