"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SHARE_SITES = void 0;
var SHARE_SITES = {
  GOOGLE: 'Google',
  ICAL: 'iCal',
  OUTLOOK: 'Outlook',
  YAHOO: 'Yahoo'
};
exports.SHARE_SITES = SHARE_SITES;